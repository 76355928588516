import React, { Fragment } from 'react'
import { useTranslation } from "react-i18next";

function Header() {
   const [t] = useTranslation("global");

   return (
      <Fragment>
         <div className="Header" id="Home">
            <div className="Header-background"></div>
            <div className="Header-content">
               <div className="Header-hero">
                  <h1 className="fw-bold">{t("header.title")} <br/> <span className="primary-color-tex">{t("header.title-bold")}</span></h1>
                  <a href="#Contact">
                     <button className="Button mt-5">{t("header.button")}</button>
                  </a>
               </div>
               <div className="Header-visuals">
                  <img src={window.location.origin + '/assets/images/Logo.png'} alt="Rebel air logo" className="img-fluid" />
               </div>
            </div>
         </div>
      </Fragment>
      
   )
}

export default Header
