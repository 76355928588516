import React, { Fragment, useEffect, useState } from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { getImages, baseUrl } from '../utils/getImages';

function CarouselPage() {
   const [images, setImages] = useState([]);

   useEffect(() => {
      getImages().then(resp => {
         setImages(resp);
      });
   }, [])

   return (
      <Fragment>
         <Carousel showArrows={true} centerSlidePercentage={true} infiniteLoop={true} dynamicHeight={true} swipeable={true}>
            {
               images.map(item => (
                  <div key={item}>
                     <img src={`${baseUrl}/uploads/${item}`} key={item} alt="carousel-img"/>
                  </div>
               ))
            }
         </Carousel>
      </Fragment>
   )
}

export default CarouselPage
