export const baseUrl = 'http://rebelair.net/api/slider';

export const getImages = async() => {
   try {
      const resp = await fetch(`${baseUrl}/getImages.php`);
   
      const results = await resp.json();
      return results;
   } catch (error) {
      console.error('error', error);
   }
}