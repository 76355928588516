import React from 'react'
import { useTranslation } from 'react-i18next';

function Best() {
   const [t] = useTranslation("global");

   return (
      <div id="AboutUs" className="mt-5 overflow-hidden">
         <div className="row">
            <div className="col-sm-12 col-md-6 p-0">
               <img src={window.location.origin + '/assets/images/20944212.jpg'} alt="" className="img-fluid" />
            </div>
            <div className="col-sm-12 col-md-6 primary-color p-5 background-points">
               <div className="d-flex justify-content-around flex-column">
                  <div className="">
                     <h5 className="">{t("about.title")}</h5>
                     <p className="display-5"><strong>{t("about.title-bold")}</strong></p>
                  </div>
                  <div className="my-5">
                     <p className="text-justify">{t("about.text1")}</p>
                     <p className="text-justify">{t("about.text3")}</p>
                  </div>
                  <div className="">
                     <a href="#!" type="button" className="btn hover-button rounded-pill me-5" style={{color:'#fff', backgroundColor: "#f42c35"}}>{t("about.button")}</a>
                     <a href="tel:(916) 883-8039" type="button" className="btn hover-button rounded-pill" style={{color:'#fff', backgroundColor: "#f42c35"}}><i className="fas fa-phone-alt"></i> (916) 883-8039</a>
                  </div>
               </div>
            </div>
         </div>
      </div>
   )
}

export default Best
