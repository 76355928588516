import React, { Fragment } from 'react'
import Footer from '../components/Footer'
import ServicesPageCard from '../components/ServicesPageCard'
import { useTranslation } from "react-i18next";

function ServicesPage() {
   const [t] = useTranslation("global");
   return (
      <Fragment>
         <div className="row banner background-points h-500 mx-0">
            <div className="col-12 my-auto text-center">
               <h3 className="display-5">{t("services.title")}</h3>
            </div>
         </div>

         <div id="servicios" className="container mt-5">
            <p className="border-left-color display-6 ">{t("services.title")}</p>
            <div className="row mt-5">
               <ServicesPageCard col="col-sm-12 col-md-6" isSmall={false} title={t("services.item-1.title")} description={t("services.item-1.description")} icon="fas fa-comments"/>
               <ServicesPageCard col="col-sm-12 col-md-6" isSmall={false} title={t("services.item-2.title")} description={t("services.item-2.description")} icon="fas fa-coins"/>
               <ServicesPageCard col="col-sm-12 col-md-6" isSmall={false} title={t("services.item-3.title")} description={t("services.item-3.description")} icon="fas fa-plug"/>
               <ServicesPageCard col="col-sm-12 col-md-6" isSmall={false} title={t("services.item-4.title")} description={t("services.item-4.description")} icon="fas fa-tools"/>
            </div>
         </div>

         <div className="row background-points mx-0 p-5 mb-5">
            <div className="col-12 text-center text-white">
               <p className="display-5">{t("utils.help")}</p>
               <a href="mailto:info@rebelair.net" className="btn btn-dark btn-dark"><i className="fa fa-envelope me-2"></i> {t("utils.contact")}</a>
            </div>
         </div>


         <Footer />
      </Fragment>
   )
}

export default ServicesPage
