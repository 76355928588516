import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Index from './pages/Index.jsx';
import NavBar from './components/Navbar.jsx'
import { I18nextProvider } from "react-i18next";
import i18next from 'i18next';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import ScrollToTop from './utils/ScrollToTop'

//translations
import global_es from './translations/es/global.json';
import global_en from './translations/en/global.json';
import ServicesPage from './pages/ServicesPage';

i18next.init({
   interpolation: {escapeValue: false},
   lng: "en",
   resources: {
      es: {
         global: global_es
      },
      en: {
         global: global_en
      },
   }
})

ReactDOM.render(
  <BrowserRouter>
      <I18nextProvider i18n={i18next}>
         <div>
            <NavBar />
            <Redirect from="/" to="/home" exact={true}/>
            <ScrollToTop />
            <Switch>
               <Route path="/home" component={Index} />
               <Route path="/services" component={ServicesPage} />
            </Switch>
         </div>
      </I18nextProvider>
  </BrowserRouter>,
  document.getElementById('root')
);