import { Fragment } from "react";
import ContactForm from "../components/ContactForm";
import Header from "../components/Header";
import Best from "../components/Best";
import Services from "../components/Services";
import Footer from "../components/Footer";
import { useTranslation } from "react-i18next";
import CarouselPage from "../components/CarouselPage";
import FeaturedServices from "../components/FeaturedServices";

function Index() {
   const [t] = useTranslation("global");
   
  return (
      <Fragment>
         <Header />
         <div id="Skills" className="container mt-5">
            <p className="display-5 text-center primary-color-text">{t("skills.title")}</p>
            <div className="row mt-5">
               <Services icon="far fa-lightbulb" title={t("skills.item-1.title")} body={t("skills.item-1.description")}/>
               <Services icon="fas fa-money-bill-wave" title={t("skills.item-2.title")} body={t("skills.item-2.description")}/>
               <Services icon="fas fa-leaf" title={t("skills.item-3.title")} body={t("skills.item-3.description")}/>
            </div>
         </div>
         <Best />
         <div className="mt-5 background-tiny-square" id="Services">
            <div className="container mb-5">
               <p className="display-5 text-center primary-color-text">{t("services.title")}</p>
               <br/><br/><br/>
               <div className="container">
                  <div className="row">
                     <FeaturedServices icon={"fas fa-comments"} title={t("services.item-1.title")} body={t("services.item-1.description")} />
                     <FeaturedServices icon={"fas fa-coins"} title={t("services.item-2.title")} body={t("services.item-1.description")} />
                     <FeaturedServices icon={"fas fa-plug"} title={t("services.item-3.title")} body={t("services.item-1.description")} />
                     <FeaturedServices icon={"fas fa-tools"} title={t("services.item-4.title")} body={t("services.item-1.description")} />
                  </div>
               </div>
            </div>
         </div>

         <div className="container">
            <div className="row">
               <div className="col">
                  <CarouselPage />
               </div>
            </div>
         </div>


         <div className="row background-contact mx-0 p-5 mb-5">
            <div className="col-12 text-white">
               <div className="container">
                  <p className="display-4">{t("banner.title")}</p>
                  <h4>{t("banner.subtitle")}</h4>
               </div>
            </div>
         </div>

         <div className="row mx-0 mb-5" id="Contact">
            <div className="col background-big-square">
               <ContactForm />
            </div>
         </div>

         {/* <div className="whatsapp hover-button">
            <i className="fab fa-whatsapp"></i>
            <span>7889-6989</span>
         </div> */}

         <Footer />
      </Fragment>
  );
}

export default Index;
