import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from "react-i18next";

function FeaturedServices({icon, title, body}) {
   const [t] = useTranslation("global");
   return (
      <div className="col-sm-12 col-md-6 col-lg-3 ">
         <div className="card custom-card-shadow mb-5 service-card">
            <div className="card-body">
               <div className="icon-circle d-flex justify-content-center">
                  <i className={`${icon} primary-color-text gradient-blue-shadow-text`}></i>
               </div>
               <br/>
               <h4 className="card-title text-center">{title}</h4>
            </div>
            <div className="card-footer border-0">
               <Link to="/services" className="card-link primary-color-text transparent">{t("utils.link")}</Link>
            </div>
         </div>
      </div>
   )
}

export default FeaturedServices
