import React from 'react'

function ServicesPageCard({col, isSmall, title, description, icon}) {
   let style = {
      width: 18+'rem',
      height: 24+'rem',
   };
   let truncateDescript = {
      height: 10+'rem',
      overflow: 'hidden'
   };

   if (!isSmall) {
      style = null;
      truncateDescript = null;
   }
   return (
      <div className={col+` center card-group`} >
         <div className="card custom-card-shadow quick-info-cards mb-5 pb-2" style={style}>
            <div className="card-img-top m-3 text-center">
               <i className={icon+` primary-color-text fs-1`}></i>
            </div>
            
            <div className="card-body">
               <h5 className="card-title">{title}</h5>
               <p className="card-text text-justify" style={truncateDescript}>{description}</p>
            </div>            
         </div>
      </div>
   )
}

export default ServicesPageCard
