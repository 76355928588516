import React from 'react'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

function Footer() {
   const [t] = useTranslation("global");

   return (
      <footer className="text-center text-lg-start">
         <div className="container p-4">
            <div className="row">
               <div className="col-lg-6 col-md-12 mb-4 mb-md-0">
                  <h4 className="text-uppercase">Rebel Air</h4>
                  <div className="d-flex justify-content-center justify-content-lg-start">
                     <img src={window.location.origin + '/assets/images/Logo.png'} alt="Rebel air logo" style={{width: "250px", height: "auto"}} />
                     {/* <div className="social-icons primary-color text-center hover-button shadow">
                        <i className="fab fa-facebook-f"></i>
                     </div>
                     <div className="social-icons primary-color-red text-center hover-button shadow">
                        <i className="fab fa-instagram"></i>
                     </div>
                     <div className="social-icons text-center hover-button shadow" style={{backgroundColor: "#3dd3e6", color: "#fff"}}>
                        <i className="fab fa-twitter"></i>
                     </div> */}
                  </div>
               </div>
         
               <div className="col-lg-3 col-md-6 mb-4 mb-md-0">
                  <h5 className="text-uppercase">Menu</h5>
                  <br/>
         
                  <ul className="list-unstyled mb-0">
                     <li>
                        <a href="#Home" className="">{t("navbar.item-1")}</a>
                     </li>
                     <li>
                        <a href="#Skills" className="">{t("navbar.item-2")}</a>
                     </li>
                     <li>
                        <a href="#AboutUs" className="">{t("navbar.item-3")}</a>
                     </li>
                     <li>
                        <Link to="/services" className="">{t("navbar.item-4")}</Link>
                     </li>
                     <li>
                        <a href="#Contact" className="">{t("navbar.item-5")}</a>
                     </li>
                  </ul>
               </div>

               <div className="col-lg-3 col-md-6 mb-4 mb-md-0">
                  <h5 className="text-uppercase mb-0">Contact</h5>
                  <br/>
         
                  <ul className="list-unstyled">
                     <li>
                        <div className="">
                           <i className="fs-6 fa fa-envelope me-2"></i>
                           <a href="mailto:info@rebelair.net">info@rebelair.net</a>
                        </div>
                     </li>
                     <li>
                        <div className="mt-4">
                           <i className="fs-6 fa fa-home me-2"></i>
                           <span>{t("address.title-1")}</span>
                           <br/>
                           <span>{t("address.content-1")}</span>
                           <br/>
                           <a href="tel:(916) 883-8039" className="text-mute">(916) 883-8039</a>
                        </div>
                     </li>
                     <li>
                        <div className="mt-4">
                           <i className="fs-6 fa fa-home me-2"></i>
                           <span>{t("address.title-2")}</span>
                           <br/>
                           <span>{t("address.content-2")}</span>
                           <br/>
                           <a href="tel:(714) 837-4052" className="text-mute">(714) 837-4052</a>
                        </div>
                     </li>
                     <li>
                        <div className="mt-4">
                           <i className="fs-6 fa fa-home me-2"></i>
                           <span>{t("address.title-3")}</span>
                           <br/>
                           <span>{t("address.content-3")}</span>
                           <a href="tel:(213)268-3136" className="text-mute">(213) 268 - 3136</a>
                           <br/>
                           <br>
                           </br>
                           <a href="mailto:jonnyn@rebelair.net" className="text-mute">jonnyn@rebelair.net</a>
                        </div>
                     </li>
                  </ul>
               </div>
            </div>
         </div>
      </footer>
   )
}

export default Footer
